// Pembee
export const pembeeEnv = process.env.NEXT_PUBLIC_PEMBEE_ENV || "";
export const isDevSite = process.env.NEXT_PUBLIC_PEMBEE_ENV === "dev";
export const isDemoSite = process.env.NEXT_PUBLIC_PEMBEE_ENV === "demo";
export const isLocalSite = process.env.NEXT_PUBLIC_PEMBEE_ENV === "local";
export const isProdSite = process.env.NEXT_PUBLIC_PEMBEE_ENV === "prod";

// Stripe
export const stripePublishableKey =
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || "";

// Help Scout
export const helpScoutBeaconId =
  process.env.NEXT_PUBLIC_HELPSCOUT_BEACON_ID || "";
